$blue: #0C53A0;
$sand: #F7E1C1;

$mobileBP: 380;

@function vw($px) {
    @return #{(($px / $mobileBP) * 100) + 'vw'};
}

$pagePaddingX: vw(30);
$pagePaddingY: vw(60);

$appPaddingBottom: vw(100);