@import '../../variables.scss';

.auctionContent {
    .auctionContainer {
        position: relative;
        padding-bottom: calc($appPaddingBottom + vw(200));

        .mascot {
            height: vw(300);
            width: vw(300);
            opacity: 0.15;
            position: fixed;
            bottom: vw(100);
            left: 50%;
            transform: translateX(-50%);
            z-index: 0;
        }

        .content {
            z-index: 1;

            header {
                text-align: center;
                font-weight: 700;
                font-size: vw(20);
                line-height: vw(24);
                margin-bottom: vw(32);
            }

            .auctions {
                display: flex;
                flex-direction: column;
                gap: vw(10);
                width: vw(320);
                margin-bottom: vw(10);

                .auction {
                    background: white;
                    border-radius: 16px;
                    display: flex;
                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                    border-radius: 16px;
                    padding: vw(20);
                    color: black;
                    justify-content: space-between;
                    gap: vw(20);

                    .imgWrapper {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        height: vw(75);
                        width: vw(75);

                        img {
                            height: vw(75);
                            width: vw(75);
                            object-fit: contain;
                            margin-bottom: vw(5);
                        }

                        span {

                            font-size: vw(12);
                            font-weight: 700;
                        }
                    }


                    .info {
                        flex-grow: 1;

                        h3 {
                            font-weight: 700;
                            font-size: vw(12);
                            line-height: vw(12);
                            margin-bottom: vw(15)
                        }

                        p {
                            display: flex;
                            justify-content: space-between;
                            font-weight: 400;
                            font-size: vw(12);
                            line-height: vw(12);
                        }

                        p:not(:last-of-type) {
                            margin-bottom: vw(5);
                        }
                    }
                }

                .invalid {
                    // pointer-events: none;
                    opacity: 50%;
                }
            }

            .auctionSearch {
                position: fixed;
                bottom: vw(120);
                left: 50%;
                transform: translateX(-50%);
                background: white;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                border-radius: 20px;
                width: calc(100% - vw(60));
                height: vw(60);
                display: flex;

                .inputWrap {
                    flex-grow: 1;
                    padding: vw(20);
                    display: flex;

                    input {
                        flex-grow: 1;
                    }
                }

                button {
                    width: vw(80);
                    height: 100%;
                    border-radius: 0;
                    border-top-right-radius: 20px;
                    border-bottom-right-radius: 20px;
                }
            }
        }

    }
}

#ion-react-wrapper {
    background: $blue;
    padding: $pagePaddingY $pagePaddingX;
    color: white;
    font-family: montserrat, sans-serif !important;
    font-weight: 400;
    font-style: normal;
    display: flex;
    flex-direction: column;
    align-items: center;

    header {
        text-align: center;
        font-weight: 700;
        font-size: vw(20);
        line-height: vw(24);
        margin-bottom: vw(32);
        position: relative;
        width: 100%;

        img {
            position: absolute;
            left: 0;
            width: vw(24);
            height: vw(24);
        }
    }

    .auctionItem {
        width: 100%;
        background: white;
        color: black;
        border-radius: 16px;
        padding: vw(20) vw(30);

        img {
            width: vw(260);
            height: vw(150);
            object-fit: contain;
            margin-bottom: vw(20);
        }

        h3 {
            font-weight: 700;
            font-size: vw(16);
            line-height: vw(20);
            text-align: center;
            margin-bottom: vw(45)
        }

        p {
            display: flex;
            justify-content: space-between;
            margin-bottom: vw(20);

            span:first-of-type {
                font-style: normal;
                font-weight: 700;
                font-size: vw(16);
                line-height: vw(20);
            }
        }

        p:last-of-type {
            margin-bottom: vw(60);
        }

        .bidContainer {
            background: rgba(238, 238, 238, 0.1);
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 16px;

            .inputWrapper {
                padding: vw(20);
                padding-bottom: 0;
                margin-bottom: vw(20);

                input {
                    font-size: vw(12);
                }
            }

            button {
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }
        }

        .invalid {
            opacity: 0.3;
            pointer-events: none;
        }
    }
}

.swiper-pagination-bullet-active {
    background: $blue  !important;
}