@import '../../variables.scss';

.sponsorsContent {
    .sponsorsContainer {
        position: relative;
        padding-bottom: calc($appPaddingBottom);

        .mascot {
            height: vw(300);
            width: vw(300);
            opacity: 0.15;
            position: fixed;
            bottom: vw(100);
            z-index: 0;
        }

        .content {
            z-index: 1;
            width: 100%;

            header {
                text-align: center;
                font-weight: 700;
                font-size: vw(20);
                line-height: vw(24);
                margin-bottom: vw(32);
                position: relative;
                width: 100%;
            }

            .poster {
                border-radius: 16px;
            }
        }
    }
}