@import "../../variables.scss";

.loginContainer {
    .sdcLogo {
        width: vw(190);
    }

    h2 {
        font-weight: 700;
        font-size: vw(16);
        line-height: vw(20);
        text-align: center;
        width: vw(270);
        margin-top: vw(30);
        margin-bottom: vw(30);
    }

    // .loginContent {
    //     width: 100%;
    //     padding: vw(30);
    //     background-color: rgba(255, 255, 255, 0.8);
    //     box-shadow: 2px 2px 3px rgba(255, 255, 255, 0.25);
    //     border-radius: 16px;
    //     position: relative;
    //     z-index: 1;
    // }


    // .mascot {
    //     position: absolute;
    //     top: 50%;
    //     left: 50%;
    //     transform: translate(-50%, -50%);
    //     z-index: -1;
    //     opacity: 0.1;
    // }

    // input {
    //     margin-bottom: vw(30);
    // }

    // .getCode {
    //     margin-bottom: vw(60);
    // }

    .loginContent {
        position: relative;



        .qrCodeContainer {
            position: relative;
            z-index: 1;
            width: vw(320);
            height: vw(320);
            background: rgba(247, 225, 193, 0.8);
            border-radius: 32px;
            padding: vw(45) vw(20);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            margin-bottom: vw(30);

            .mascot {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                opacity: 0.1;
                z-index: 0;
            }

            .qr {
                width: vw(180);
                height: vw(180);
            }

            h5 {
                // width: 100%;
                font-weight: 700;
                font-size: vw(12);
                line-height: vw(20);
                color: $blue;
                text-transform: uppercase;
            }
        }

        .or {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .line {
                width: vw(130);
                height: vw(4);
                background: $sand;
            }

            span {
                font-weight: 700;
                font-size: vw(16);
                line-height: vw(20);
                text-align: center;
                color: $sand;
            }
        }

        .manualLogin {

            margin-top: vw(30);
            background: white;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 20px;
            width: calc(100%);
            height: vw(60);
            display: flex;

            .inputWrap {
                flex-grow: 1;
                padding: vw(20);
                display: flex;

                input {
                    flex-grow: 1;
                    // padding-bottom:
                    border-bottom: 2px solid $blue;
                }
            }

            button {
                width: vw(80);
                height: 100%;
                border-radius: 0;
                border-top-right-radius: 20px;
                border-bottom-right-radius: 20px;
            }
        }
    }

}

// .test {
//     height: vw(320);
//     width: vw(320);
// }
.test {
    width: 100%;
    height: 100%;

    div {
        width: 100%;
        /* padding-top: 100%; */
        overflow: hidden;
        position: relative;
        height: 100%;
        padding-top: 0 !important;
        border-radius: 16px;

        video {
            transform: scale(1.5);
        }
    }
}